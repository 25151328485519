.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #BE001E;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 11px 20px 9px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.button:hover,
.button:focus {
  color: #fff !important;
  background-color: #cf203c;
}
.cb-home {
  height: 106px;
  margin: 20px 0 20px 5%;
}
.whitehome {
  top: 20px;
  height: 106px;
}
.cb-pages {
  margin-top: 146px;
}
@media (max-width: 1023px) {
  .footpart--1 {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .farnorth {
    font-size: 13px;
    line-height: 1.38461538;
  }
  .farnorth strong {
    font-size: 16px;
    line-height: 1.5;
  }
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main .cb-album .body,
.main .head,
.main .foot,
.main .part {
  margin-right: 2.32198142%;
  margin-left: 2.32198142%;
  width: 95.35603715%;
}
.main .tiny {
  width: 45.35603715%;
}
.main > .slim {
  width: 50%;
}
.main > .slim .head,
.main > .slim .foot,
.main > .slim .part {
  margin-right: 4.64396285%;
  margin-left: 4.64396285%;
  width: 90.7120743%;
}
.main > .slim.cb-album .body {
  margin-right: 4.64396285%;
  margin-left: 4.64396285%;
  width: 90.7120743%;
}
.main > .slim .tiny {
  width: 90.7120743%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main .cb-album .body,
.cb-page-layout2 .main .head,
.cb-page-layout2 .main .foot,
.cb-page-layout2 .main .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.cb-page-layout2 .main .tiny {
  width: 46.91991786%;
}
.cb-page-layout2 .main > .slim {
  width: 50%;
}
.cb-page-layout2 .main > .slim .head,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim.cb-album .body {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 93.83983573%;
}
.base,
.areaTen {
  width: 100%;
}
.base > .unit,
.areaTen > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .cb-album .body,
.areaTen .cb-album .body,
.base .head,
.areaTen .head,
.base .foot,
.areaTen .foot,
.base .part,
.areaTen .part {
  margin-right: 2.98804781%;
  margin-left: 2.98804781%;
  width: 94.02390438%;
}
.base .tiny,
.areaTen .tiny {
  width: 27.35723772%;
}
.base > .slim,
.areaTen > .slim {
  width: 33.33333333%;
}
.base > .slim .head,
.areaTen > .slim .head,
.base > .slim .foot,
.areaTen > .slim .foot,
.base > .slim .part,
.areaTen > .slim .part {
  margin-right: 8.96414343%;
  margin-left: 8.96414343%;
  width: 82.07171315%;
}
.base > .slim.cb-album .body,
.areaTen > .slim.cb-album .body {
  margin-right: 8.96414343%;
  margin-left: 8.96414343%;
  width: 82.07171315%;
}
.base > .slim .tiny,
.areaTen > .slim .tiny {
  width: 82.07171315%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest .cb-album .body,
.farwest .head,
.farwest .foot,
.farwest .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.farwest .tiny {
  width: 46.91991786%;
}
.farwest > .slim {
  width: 50%;
}
.farwest > .slim .head,
.farwest > .slim .foot,
.farwest > .slim .part {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.farwest > .slim.cb-album .body {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.farwest > .slim .tiny {
  width: 93.83983573%;
}
.main {
  width: 104.87012987%;
  margin-left: -2.43506494%;
}
.cb-page-layout2 .main {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
.base,
.areaTen {
  width: 106.3559322%;
  margin-left: -3.1779661%;
}
.farwest {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
.base .seam,
.areaTen .seam {
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
  border-left: 2px solid #E5E5E5;
}
.base .seam .body,
.areaTen .seam .body {
  margin-top: -15px;
  margin-bottom: -15px;
}
/*# sourceMappingURL=./screen-medium.css.map */